import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { siteSettings } from "../../settings";

export default function LegalText() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
        {/* <p className="text-base font-semibold leading-7 text-indigo-600">
            Introducing
          </p> */}
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Legal
        </h1>

        <span>
          <ul>
            <li style={{ color: "#0c6cf2", textDecoration: "underline" }}>
              <a href="/terms">Terms and Conditions</a>
            </li>
            <li style={{ color: "#0c6cf2", textDecoration: "underline" }}>
              <a href="/privacy">Privacy Policy</a>
            </li>
          </ul>
        </span>

        <p className="mt-6 text-xl leading-8">
          Brand and Relationship Management Announcement
          <br />
          <br />
          Defamation and Disinformation
          <br />
          <br />
          The spread of Defamation or Disinformation of the platforms, brands,
          products and services, as well as those of all Affiliated platforms
          and providers, is a legal offense.
          <br />
          <br />
          Due to current ongoing legal action against a group of criminals, and
          the platforms they use for their criminality, our legal team, along
          with the Brand and Relationship Management team, have issued
          instructions to suspends the accounts of the small number of offending
          members who are working with the criminals to further their cause.
          <br />
          <br />
          Whoever is found to be spreading this illegal content, will also be
          considered accessories, and their details handed over to the legal
          team handling these cases.
          <br />
          <br />
          The criminals are using the BehindMLM platform, as well as smaller
          platforms to hide their identities.
          <br />
          <br />
          Our legal team is communicating directly with Google to uncover these
          criminals.
          <br />
          <br />
          If you are the recipient of any articles or videos causing Defamation
          or Disinformation, please screenshot the offending material and send
          it directly to the legal team.
          <br />
          <br />
          We will NOT tolerate criminals or acts of abuse against our brand,
          associated brands, products or services.
        </p>

        {/* <div className="mt-10 max-w-4xl">
          
          </div> */}

        {/* <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-[600] tracking-tight text-gray-900">
              Everything you need to get up and running
            </h2>
            <p className="mt-6">
              This Privacy Policy (the “Policy” ) applies to Brass Technologies
              Limited’s online interface (i.e., website or mobile application) and
              any Brass Invoicing Limited’s affiliate, subsidiary or online
              interface that is linked to this Policy, (individually referred to
              as a “site”, and, collectively, “sites” ). The term “the company” or
              "Brass" or "we" or "us" or "our" in this Policy shall refer to Brass
              Invoicing Limited, and any banking and non-banking affiliates or
              subsidiaries of Brass Invoicing Limited that is linked to this
              Policy. This Policy describes how the Sites may collect, use, and
              share information from or about you, and explains how information
              may be collected and used for advertising purposes.
            </p>
            <p className="mt-8">
              Brass takes the privacy of your information very seriously. This
              Policy explains how and for what purposes we may use the information
              collected from you via the Site.
            </p>
  
            <p className="mt-8">
              Additional information on our Privacy Policy may be found on our
              Sites and within Frequently Asked Questions (FAQs) as set out on the
              Sites.
            </p>
  
            <p className="mt-8">
              Please read this Policy carefully. By using the Site and any
              services we offer via the Site, you are agreeing to be bound by this
              Policy with respect to the information collected about you via this
              Site.
            </p>
  
            <p className="mt-8">
              You may use the Site only for lawful purposes. You may not use the
              Site:
            </p>
  
            <ul>
              <li>
                In any way that breaches any applicable local, national or
                international law or regulation.
              </li>
            </ul>
          </div> */}

        {/* <div className="mt-16 max-w-2xl">
            <h2 className="text-2xl font-[600] tracking-tight text-gray-900">
              Everything you need to get up and running
            </h2>
            <p className="mt-6">
              Purus morbi dignissim senectus mattis adipiscing. Amet, massa quam
              varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales
              cursus tristique. Tincidunt sed tempus ut viverra ridiculus non
              molestie. Gravida quis fringilla amet eget dui tempor dignissim.
              Facilisis auctor venenatis varius nunc, congue erat ac. Cras
              fermentum convallis quam.
            </p>
            <p className="mt-8">
              Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
              enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
              praesent donec est. Odio penatibus risus viverra tellus varius sit
              neque erat velit.
            </p>
          </div> */}
      </div>
    </div>
  );
}
