import { Header } from "../components/header/Header";
import FooterOne from "../components/footer/FooterOne";
import LegalText from "../components/content/LegalText";

const Legal = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#0c0f19" }}>
        {/* <FeaturesTwoWithImage /> */}
        <Header />
      </div>
      <LegalText />
      <FooterOne />
    </div>
  );
};

export default Legal;
